import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { filter, take } from 'rxjs';
import { UTM_PARAMS_KEY } from './utm-params-key';
import { ParamsJSON } from './utm-params.interface';
import { isEmptyObject } from '@/_utils/objects/is-empty-object';

@Injectable({ providedIn: 'root' })
export class UtmParamsService {
	private readonly activatedRoute = inject(ActivatedRoute);

	saveUTMParams(): void {
		this.activatedRoute.queryParamMap
			.pipe(
				filter((paramMap: ParamMap) => paramMap.keys.length > 0),
				take(1),
			)
			.subscribe((paramMap: ParamMap) => {
				const paramsJSON = this.filterParams(paramMap);
				if (paramsJSON) {
					localStorage.setItem(UTM_PARAMS_KEY, JSON.stringify({ ...paramsJSON, date: new Date() }));
				}
			});
	}

	readUTMParams(): ParamsJSON {
		const utmParams = { ...JSON.parse(localStorage.getItem(UTM_PARAMS_KEY)) };
		if (utmParams?.date) {
			delete utmParams.date;
		}

		return isEmptyObject(utmParams) ? null : utmParams;
	}

	readUTMDate(): Date | null {
		const utmParams = JSON.parse(localStorage.getItem(UTM_PARAMS_KEY));
		if (utmParams?.date) {
			return new Date(utmParams.date);
		}
		return null;
	}

	private filterParams(paramMap: ParamMap): ParamsJSON {
		const params = paramMap.keys
			.filter((key) => key.includes('utm'))
			.reduce((acc: ParamsJSON, key: string) => {
				acc[this.removeUTMPrefix(key)] = paramMap.get(key);
				return acc;
			}, {});

		if (JSON.stringify(params) === '{}') {
			return null;
		}

		return params;
	}

	private removeUTMPrefix(str: string): string {
		return str.replace(/utm_(\w+)/g, '$1');
	}
}
